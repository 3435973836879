import React, { useState} from 'react';
import styled from '@emotion/styled';
import axios from 'axios';

import Row from './prebuilt/Row';
import BillingDetailsFields from './prebuilt/BillingDetailsFields';
import SubmitButton from './prebuilt/SubmitButton';
import CheckoutError from './prebuilt/CheckoutError';
import SuccessModal from '../SuccessModal/SuccessModal'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const CheckoutForm = ({ price }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [success, setSuccess] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const name = ev.target.name.value;
    const email = ev.target.email.value;
    const city = ev.target.city.value;
    const line1 = ev.target.address.value;
    const state = ev.target.state.value;
    const postal_code = ev.target.zip.value;

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
      },
    };

    setProcessingTo(true);

    try {
      const { data: clientSecret } = await axios.post(
        'https://yn4k2ix8hc.execute-api.us-east-2.amazonaws.com/latest/create-payment-intent',
        {
          amount: price * 100,
        }
      );

      const cardElement = elements.getElement(CardElement);

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      //  send email to ssfw to notify of purchase!
      await axios.post(
        'https://m74affl1i1.execute-api.us-east-1.amazonaws.com/Prod/submitForm',
        {
          name,
          email,
          price,
          city,
          line1,
          state,
          postal_code,
        }
      );

      setSuccess(true);//  Display SuccessModal!
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#fff',
        '::placeholder': {
          color: 'black',
        },
      },
      invalid: {
        color: '#FFC7EE',
        iconColor: '#FFC7EE',
      },
      complete: {},
    },
    hidePostalCode: true,
  };

  return (
    <>
    <form onSubmit={handleFormSubmit}>
      <Row>
        <BillingDetailsFields />
      </Row>
      <Row>
        <CardElementContainer>
          <CardElement options={cardElementOptions} />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
        <SubmitButton disabled={isProcessing}>
          {isProcessing ? 'Processing...' : `Pay $${price}`}
        </SubmitButton>
      </Row>
    </form>
    {success && <SuccessModal />}
    </>
  );
};

export default CheckoutForm;
