import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

import Modal from '../Modal/Modal';

import styles from './SuccessModal.module.css';

const SuccessModal = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100);
  });

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal show={isOpen} handleClose={hideModal}>
      <Confetti width={width} height={height} numberOfPieces={100} />
      <div className={styles.message}>
        Congratulations! Expect an email soon!
      </div>
    </Modal>
  );
};

export default SuccessModal;
