import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Layout from './components/Layout/Layout';
import StartOptions from './sections/StartOptions/StartOptions';
import WebsiteDesign from './sections/WebsiteDesign/WebsiteDesign';
import Ecommerce from './sections/Ecommerce/Ecommerce';
import CustomApp from './sections/CustomApp/CustomApp';
// import SocialMedia from './sections/SocialMedia/SocialMedia';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/ecommerce">
          <Layout>
            <Ecommerce />
          </Layout>
        </Route>
        {/* <Route path="/social-media">
          <Layout>
            <SocialMedia />
          </Layout>
        </Route> */}
        <Route path="/custom-app">
          <Layout>
            <CustomApp />
          </Layout>
        </Route>
        <Route path="/website-design">
          <Layout>
            <WebsiteDesign />
          </Layout>
        </Route>
        <Route path="/">
          <StartOptions />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
