import styled from '@emotion/styled';

const Row = styled.div`
  margin: 30px auto;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 rgb(192, 168, 214);
  border-radius: 4px;
  background-color: rgb(194, 209, 245);
  position: relative;
`;

export default Row;
