import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem, faSignature, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faConnectdevelop } from '@fortawesome/free-brands-svg-icons';

import goodThingsStyles from './goodThings.module.css';

const GoodThings = () => {
  return (
    <div className={goodThingsStyles.sectionContainer}>
      <h1 className={goodThingsStyles.heading1}>A few things we're great at</h1>
      <div className={goodThingsStyles.subHeaders}>
        <p className={goodThingsStyles.paragraph1}>
          Whether you are a new or established business we can increase your
          professional presence.
        </p>
        <div className={goodThingsStyles.cardsContainer}>
          <div className={goodThingsStyles.card1}>
            <div className={goodThingsStyles.fa}>
              <FontAwesomeIcon icon={faSignature} />
            </div>
            <div className={goodThingsStyles.goodItem}>
              <div>Domain Names and Email</div>
              <div className={goodThingsStyles.text}>
                Maintain a professional look with a new business email.
              </div>
            </div>
          </div>
          <div className={goodThingsStyles.card2}>
            <div className={goodThingsStyles.fa}>
              <FontAwesomeIcon icon={faGem} />
            </div>
            <div className={goodThingsStyles.goodItem}>
              Personal or Business Branding
              <div className={goodThingsStyles.text}>
                We’ll help you develop a professional or personal brand such as
                logo design.
              </div>
            </div>
          </div>
          <div className={goodThingsStyles.card3}>
            <div className={goodThingsStyles.fa}>
              <FontAwesomeIcon icon={faGlobe} />
            </div>
            <div className={goodThingsStyles.goodItem}>
              Social Media Presence
              <div className={goodThingsStyles.text}>
                Create a social media presence that will help attract new and
                potential customers.
              </div>
            </div>
          </div>
          <div className={goodThingsStyles.card4}>
            <div className={goodThingsStyles.fa}>
              <FontAwesomeIcon icon={faConnectdevelop} />
            </div>
            <div className={goodThingsStyles.goodItem}>
              Modern Design
              <div className={goodThingsStyles.text}>
                Bring your website into the 21st century, with designs that are
                easy to use and pleasing to the eye.{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={goodThingsStyles.dream}>
        This is your business more profitable.
      </div>
    </div>
  );
};

export default GoodThings;
