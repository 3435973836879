import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import navbarStyles from './navbar.module.css';
import mobileStyles from './mobile-nav.module.css';
import logo from './ssfw-logo.png';
import mobileLogo from './favicon.ico';
import hamburger from './hamburger.png';

const Navbar = () => {
  const [isOpen, toggleMobileNav] = useState(false);
  const [isNotTop, toggleTopClass] = useState(false);
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y === 0) {
      toggleTopClass(false);
    } else {
      toggleTopClass(true);
    }
  });

  return (
    <header className={navbarStyles.header}>
      <nav className={isNotTop ? navbarStyles.navNotTop : navbarStyles.nav}>
        <Link to="/">
          <img
            className={navbarStyles.logo}
            src={logo}
            alt="Stoneburner Software Logo"
          />
        </Link>
        {/* <ul className={navbarStyles.navList}>
          <li className={navbarStyles.navItem}>
            <Link
              className={navbarStyles.navItem}
              // activeClassName={navbarStyles.activeNavItem}
              to="/"
            >
              HOME
            </Link>
          </li>
          <li className={navbarStyles.navItem}>
            <Link
              className={navbarStyles.navItem}
              // activeClassName={navbarStyles.activeNavItem}
              to="/#services"
            >
              ABOUT
            </Link>
          </li>
          <li className={navbarStyles.navItem}>
            <Link
              className={navbarStyles.navItem}
              // activeClassName={navbarStyles.activeNavItem}
              to="/#contact"
            >
              CONTACT
            </Link>
          </li>
          <li className={navbarStyles.navItem}>
            <Link
              className={navbarStyles.navItem}
              // activeClassName={navbarStyles.activeNavItem}
              to="/#testimonials"
            >
              TESTIMONIALS
            </Link>
          </li>
        </ul> */}
      </nav>

      {/* --------------------MOBILE NAV ---------------------------- */}

      <img
        className={mobileStyles.hamburger}
        src={hamburger}
        onClick={() => toggleMobileNav(!isOpen)}
        alt=""
      />
      <Link
        // activeClassName={mobileStyles.activeNavItem}
        to="/"
      >
        <img
          src={mobileLogo}
          alt="Stoneburner Software Logo"
          className={mobileStyles.logo}
        />
      </Link>
      <nav
        className={mobileStyles.nav}
        style={{ display: isOpen ? 'flex' : 'none' }}
      >
        <ul className={mobileStyles.navItems}>
          <li className={mobileStyles.navItem}>
            <Link
              className={mobileStyles.navItem}
              // activeClassName={mobileStyles.activeNavItem}
              to="/website-design"
              onClick={() => toggleMobileNav(!isOpen)}
            >
              WEBSITE DESIGN
            </Link>
          </li>

          <li
            className={mobileStyles.navItem}
            onClick={() => toggleMobileNav(!isOpen)}
          >
            <Link
              className={mobileStyles.navItem}
              // activeClassName={mobileStyles.activeNavItem}
              to="/ecommerce"
            >
              ECOMMERCE
            </Link>
          </li>
          <li
            className={mobileStyles.navItem}
            onClick={() => toggleMobileNav(!isOpen)}
          >
            <Link
              className={mobileStyles.navItem}
              // activeClassName={mobileStyles.activeNavItem}
              to="/custom-app"
            >
              CUSTOM APPLICATION
            </Link>
          </li>
          <li
            className={mobileStyles.navItem}
            onClick={() => toggleMobileNav(!isOpen)}
          >
            <Link
              className={mobileStyles.navItem}
              // activeClassName={mobileStyles.activeNavItem}
              to="/"
            >
              <img
                className={mobileStyles.logoInWindow}
                src={mobileLogo}
                alt=""
              />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
