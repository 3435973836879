import React from "react";
import footerStyles from "./Footer.module.css";
// import themeDev from './themeDev.svg';

const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.themeDev}>
        <div className={footerStyles.themeDevText}>
          <div>Stoneburner Software, LLC, ©2022</div>
          <div>Norwalk, OH</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
