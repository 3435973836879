import React from 'react';
import thinkBeyondStyles from './thinkBeyond.module.css';
import pic1 from './computer-2569652.jpg';
import pic2 from './apple-1838564.jpg';
import pic3 from './laptop-2559795.jpg';

const ThinkBeyond = () => {
  return (
    <div className={thinkBeyondStyles.sectionContainer}>
      <h5>THINK BEYOND</h5>
      <h2>
        Your business with unlimited power and customization possibilities
      </h2>
      <div className={thinkBeyondStyles.imageContainer}>
        <div className={thinkBeyondStyles.gridpic1}>
          <img className={thinkBeyondStyles.pic} src={pic1} alt="" />
        </div>
        <div className={thinkBeyondStyles.gridpic2}>
          <img className={thinkBeyondStyles.pic} src={pic2} alt="" />
        </div>
        <div className={thinkBeyondStyles.gridpic3}>
          <img className={thinkBeyondStyles.pic} src={pic3} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ThinkBeyond;
