import React, { useState } from 'react';

import Button from '../../../components/Button/Button';

import 'react-datepicker/dist/react-datepicker.css';

import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import contactStyles from './Contact.module.css';
import SuccessModal from '../../SuccessModal/SuccessModal';

// import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    message: '',
    email: '',
  });
  const [startDate, setStartDate] = useState(new Date());
  const { name, message, email } = formData;
  const [success, setSuccess] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        'https://m74affl1i1.execute-api.us-east-1.amazonaws.com/Prod/submitForm',
        {
          startDate,
          name,
          email,
          message,
        }
      )
      .then(() => {
        setSuccess(true);
      });
  };

  return (
    <>
      <div className={contactStyles.datePicker}>
        Pick a date and time and we'll chat about your concerns.
      </div>
      <DatePicker
        className={contactStyles.datePicker}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        showTimeSelect
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
      />
      <Form className="m3" name="contact" onSubmit={(e) => onSubmit(e)}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Your name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            required
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>
            How would you want to chat? (email, FaceTime, Skype, etc.)
            <div className={contactStyles.noDatePicker}>
              When day and time would be best?
            </div>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            type="text"
            placeholder="Type your message..."
            name="message"
            value={message}
            onChange={(e) => onChange(e)}
            required
          />
        </Form.Group>

        <div className={contactStyles.buttonContainer}>
          {/* <div className={contactStyles.interest}>
            <div>Interested but not convinced?</div>
            <div>
              Set up a time for us to chat, and I will a set you up with a demo
              store for free.
            </div>
            <div>*Limited time only</div>
          </div> */}

          <Button>Send Message</Button>
          {success && <SuccessModal />}
        </div>
      </Form>
    </>
  );
}

export default Contact;
