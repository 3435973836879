import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card1 from '@bit/stoneburnersfw.surfaces.card1';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift } from '@fortawesome/free-solid-svg-icons';

import { PageView, initGA, Event } from '../../components/Tracking/index';

import styles from './StartOptions.module.css';

import laptop from './laptop-solid.svg';
import shoppingCart from './shopping-cart-solid.svg';
import mobile from './mobile-alt-solid.svg';

const StartOptions = () => {
  useEffect(() => {
    initGA('UA-138952594-1');
    PageView();
  }, []);

  return (
    <div className={styles.container}>
      <Card1 title="What service are you most interested in?">
        <div className={styles.gridContainer}>
          <Link
            onClick={() =>
              Event(
                'website-design',
                'Clicked on website-design',
                'from StartOptions'
              )
            }
            className={styles.link}
            to="/website-design"
          >
            <div className={styles.gridItem}>
              <img className={styles.icons} alt="" src={laptop} srcSet="" />
              <div className={styles.serviceItem}>Website Design</div>
            </div>
          </Link>
          {/* <Link className={styles.link} to="/social-media">
            <div className={styles.gridItem}>
              <img className={styles.icons} alt="" src={globe} srcSet="" />
              <div className={styles.serviceItem}>Social Media</div>
            </div>
          </Link> */}
          <Link
            onClick={() =>
              Event('ecommerce', 'Clicked on ecommerce', 'from StartOptions')
            }
            className={styles.link}
            to="/ecommerce"
          >
            <div className={styles.gridItem}>
              <img
                className={styles.icons}
                alt=""
                src={shoppingCart}
                srcSet=""
              />
              <div className={styles.serviceItem}>Ecommerce</div>
            </div>
          </Link>
          <Link
            onClick={() =>
              Event('custom-app', 'Clicked on custom-app', 'from StartOptions')
            }
            className={styles.link}
            to="/custom-app"
          >
            <div className={styles.gridItem}>
              <img className={styles.icons} alt="" src={mobile} srcSet="" />
              <div className={styles.serviceItem}>Custom Application</div>
            </div>
          </Link>
        </div>
        <div className={styles.gift}>
          <FontAwesomeIcon icon={faGift} /> Your free gift offer is ahead
        </div>
      </Card1>
    </div>
  );
};

export default StartOptions;
