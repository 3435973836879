import React from 'react';
import heroStyles from './Hero.module.css';
import seanImage from './sean-github.jpg';

const Hero = ({ header1, header2 }) => {
  return (
    <div className={heroStyles.container}>
      <div className={heroStyles.imageContainer}>
        <img className={heroStyles.image} src={seanImage} alt="Logo" />
      </div>
      <div className={heroStyles.headers}>
        <div className={heroStyles.header}>{header1}</div>
        <div className={heroStyles.header}>{header2}</div>
      </div>
    </div>
  );
};

export default Hero;
