import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import styles from './Layout.module.css';

const Layout = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Navbar />
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
