import React from 'react';
import heroStyles from './Hero.module.css';

const Hero = () => {
  return (
    <div className={heroStyles.hero}>
      <div className={heroStyles.text}>
        <div className={heroStyles.point1}>Web Design for your Profession</div>
      </div>
    </div>
  );
};

export default Hero;
