import React, { useEffect } from "react";

import { PageView, initGA } from "../../components/Tracking/index";
import Hero from "./Hero/Hero";
import ThinkBeyond from "./thinkBeyond/thinkBeyond";
import GoodThings from "./goodThings/goodThings";
import Packages from "../../components/Packages/Packages";
import Learn from "../../components/Learn/Learn";
// import Team from "../sections/team/team"

// import Contact from "../sections/contact/contact"
import Testimonials from "./Testimonials/Testimonials";
import homepageStyles from "./homepage.module.css";

// import "bootstrap/dist/css/bootstrap.min.css"

const WebsiteDesign = () => {
  useEffect(() => {
    initGA("UA-138952594-1");
    PageView();
  }, []);
  const options = [
    {
      optionHeading: "One Month Web Development Starting at $400",
      p1: "Personal Dedication",
      p2: "You will have my dedication for one full month to guide your site from start to success!",
      price: "400",
    },
    {
      optionHeading: "Two Week Web Development Starting at $250",
      p1: "Personal Dedication",
      p2: "You will have my dedication for two entire weeks to guide your site from start to success!",
      price: "250",
    },
  ];

  return (
    <>
      <Hero />
      <div className={homepageStyles.sectionContainer}>
        <ThinkBeyond />
        <GoodThings />
      </div>
      {/* <Packages options={options} /> */}
      <Learn />
      {/* <Team />
      <Contact /> */}
      <Testimonials />
    </>
  );
};

export default WebsiteDesign;
