import React, { useEffect } from "react";

import { PageView, initGA } from "../../components/Tracking/index";
import Hero from "../../components/Hero/Hero";
import DemoStore from "../../components/DemoSelection/DemoSelection";
import Packages from "../../components/Packages/Packages";
import Learn from "../../components/Learn/Learn";

const Ecommerce = () => {
  useEffect(() => {
    initGA("UA-138952594-1");
    PageView();
  }, []);

  const options = [
    {
      optionHeading: "Two Month Ecommerce Development Starting at $1,000",
      p1: "Personal Dedication",
      p2: "You will have my dedication for two full months to guide your store from start to success!",
      price: "1000",
    },
    {
      optionHeading: "One Month Ecommerce Development Starting at $600",
      p1: "Personal Dedication",
      p2: "You will have my dedication for an entire month to guide your store from start to success!",
      price: "600",
    },
  ];

  const demoListItems = [
    "Analytics - Optimize your conversion rates to maximize profit or business goals.",
    "Major Payments - Stripe, Paypal, Visa, Master Card, etc.",
    "Complete Design - Responsive themes and overall design and style",
    "Admin Panel - Choose your own products, keep track of inventory and customers, and know when and where to ship.",
  ];

  return (
    <>
      <Hero
        header1="Want a relaxing life by selling products or services?"
        header2="Allow me to help you on your way to selling online with style."
      />
      <DemoStore
        title="Comfort and Elegance - A Demo Store"
        image={"./ipad-ecom-port.jpg"}
        modalImage={"./1.jpg"}
        demoListItems={demoListItems}
      />
      {/* <Packages options={options} /> */}
      <Learn />
    </>
  );
};

export default Ecommerce;
