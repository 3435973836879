import React from 'react';
import testimonialsStyles from './Testimonials.module.css';

const Testimonials = () => {
  return (
    <div className={testimonialsStyles.sectionContainer}>
      <h1 className="text-white">Client Testimonials</h1>
      <p className="text-white">
        Don’t take our word for it – here’s what our clients say:
      </p>
      <blockquote className="blockquote mb-0">
        <div className="text-white">
          "The creativity and workmanship was outstanding, well thought out."
          <footer className="blockquote-footer">
            Mike Huff with <cite title="Source Title">stumpmasters.net</cite>
          </footer>
        </div>
      </blockquote>
      <br />
      <blockquote className="blockquote mb-0">
        <div className="text-white">
          "Their professional designing and attention to detail helped me along
          the path to a fresh start in doing the career I love to do!"
          <footer className="blockquote-footer">
            Steve & Tannir's Complete Auto Detailing
          </footer>
        </div>
      </blockquote>
    </div>
  );
};

export default Testimonials;
