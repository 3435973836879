import React, { useEffect } from "react";

import { PageView, initGA } from "../../components/Tracking/index";
import Hero from "../../components/Hero/Hero";
import DemoApp from "../../components/DemoSelection/DemoSelection";
import Packages from "../../components/Packages/Packages";
import Learn from "../../components/Learn/Learn";

const CustomApp = () => {
  useEffect(() => {
    initGA("UA-138952594-1");
    PageView();
  }, []);

  const options = [
    {
      optionHeading: "Two Month Application Development Starting at $1,000",
      p1: "Personal Dedication",
      p2: "You will have my dedication and expertise for two full months!",
      price: "1000",
    },
    {
      optionHeading: "One Month Application Development Starting at $600",
      p1: "Personal Dedication",
      p2: "You will have my dedication and expertise for an entire month!",
      price: "600",
    },
  ];

  const demoListItems = [
    "Full Stack - Complete design and development for any device -  from web to native mobile (iPhone or Android) to desktop.",
    "API Integration and Development - Handling requests and responses to server data, including real-time websocket data.",
    "Scalable and Reliable - Using load balancers and clusters.",
    "Database - Create, read, update and delete operations.",
  ];

  return (
    <>
      <Hero
        header1="Do you have a business process that needs automation?"
        header2="Allow me to help with a custom application."
      />
      <DemoApp
        title="Example - Barcode Scanner with Computer Vision"
        image={"./mern.jpg"}
        modalImage={"./mern.jpg"}
        demoListItems={demoListItems}
      />
      {/* <Packages options={options} /> */}
      <Learn />
    </>
  );
};

export default CustomApp;
