import React from 'react';

import cx from 'classnames';

import styles from './Modal.module.css';

const Modal = (props) => {
  return (
    <div className={cx(styles.container, { [styles.hide]: !props.show })}>
      <div className={styles.closeButton} onClick={props.handleClose}>
        &times;
      </div>
      <div className={styles.modal}>{props.children}</div>
    </div>
  );
};

export default Modal;
