import React from 'react';
import Card1 from '@bit/stoneburnersfw.surfaces.card1';

import Contact from './Contact/Contact';

// import 'react-datepicker/dist/react-datepicker.css';

const Learn = () => {
  return (
    <Card1 title={'Learn More'}>
      <Contact />
    </Card1>
  );
};

export default Learn;
