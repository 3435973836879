import React, { useState, useEffect } from 'react';
import Card1 from '@bit/stoneburnersfw.surfaces.card1';
import Modal from '../Modal/Modal';

import { initGA, Event } from '../../components/Tracking/index';

import demoStoreStyles from './DemoSelection.module.css';

const DemoStore = ({ title, image, demoListItems, modalImage }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    initGA('UA-138952594-1');
  }, []);

  const hideModal = () => {
    setIsOpen(false);
  };

  const showModal = () => {
    setIsOpen(true);
    Event('DemoSelection', `Clicked on modal ${title}`, 'from DemoSelection');
  };

  return (
    <>
      <Modal show={isOpen} handleClose={hideModal}>
        <div className={demoStoreStyles.modalContainer}>
          <div className={demoStoreStyles.modalTitle}>{title}</div>
          <img
            className={demoStoreStyles.modalImage}
            src={modalImage}
            alt="An example"
          />
        </div>
      </Modal>
      <Card1 title={title} titleBgColor="">
        <div className={demoStoreStyles.container}>
          <div className={demoStoreStyles.item} onClick={showModal}>
            <img
              className={demoStoreStyles.image}
              src={image}
              alt="An example"
            />
          </div>
          <ul className={demoStoreStyles.unordered}>
            {demoListItems.map((item) => {
              return (
                <li key={item} className={demoStoreStyles.listItem}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </Card1>
    </>
  );
};

export default DemoStore;
